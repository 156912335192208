module.exports = [{
      plugin: require('/Users/obsidianart/HDD/GIT/enigmibot-website/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/obsidianart/HDD/GIT/enigmibot-website/src/intl","languages":["it"],"defaultLanguage":"it","redirect":true,"redirectComponent":"/Users/obsidianart/HDD/GIT/enigmibot-website/src/components/redirect.js"},
    },{
      plugin: require('/Users/obsidianart/HDD/GIT/enigmibot-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/obsidianart/HDD/GIT/enigmibot-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
